import { Alpine as IAlpine } from 'alpinejs';
import LazyLoad from "vanilla-lazyload";

export default function (Alpine: IAlpine) {
    const lazyLoad = new LazyLoad({
        threshold: 0,
        elements_selector: '[lazy]',
        class_loading: 'lazy-loading',
        class_loaded: 'lazy-loaded',
        class_applied: 'lazy-bg-loaded',
        class_error: 'lazy-error',
    });

    Alpine.magic('lazyLoad', () => lazyLoad);
}
