import { AlpineComponent } from 'alpinejs';

type WhatsappModalData = {
    whatsappModalOpen: () => void;
    whatsappModalClose: () => void;
    whatsappModalConsent: (e: Event) => void;
    whatsappModalSend: (e: Event) => void;
}

export default ():AlpineComponent<WhatsappModalData> => ({
    init () {
        this.whatsappModalOpen();
    },

    whatsappModalOpen() {
        const triggerElements = document.querySelectorAll('.whatsapp_modal_trigger');
        if (triggerElements) {
            triggerElements.forEach((element) => {
                element.addEventListener('click', (e) => {
                    const currentTarget = e.currentTarget as HTMLElement;
                    if (currentTarget) {
                        const whatsappNumber = currentTarget.dataset.phone;
                        if (whatsappNumber) {
                            const modal = document.querySelector('.whatsapp-modal') as HTMLElement;
                            if (modal) {
                                modal.dataset.phone = whatsappNumber;
                                modal.classList.add('active');
                            }
                        }

                        const whatsappText = currentTarget.dataset.whatsapptext;
                        if (whatsappText) {
                            const modal = document.querySelector('.whatsapp-modal') as HTMLElement;
                            if (modal) {
                                modal.dataset.whatsappText = whatsappText;
                                modal.classList.add('active');
                            }
                        }

                        document.body.classList.add('overflow-hidden');
                    }
                });
            });
        }
    },

    whatsappModalClose() {
        const modal = document.querySelector('.whatsapp-modal') as HTMLElement;
        if (modal) {
            modal.removeAttribute("data-phone");
            modal.classList.remove('active');

            const consent = document.getElementById('whatsapp-consent') as HTMLInputElement;
            if (consent) {
                consent.checked = false;
                consent.classList.remove('is-invalid');
            }

            const sendBtn = document.querySelector('.whatsapp-modal-btn') as HTMLAnchorElement;
            if (sendBtn) {
                sendBtn.href = "javascript:0";
                sendBtn.removeAttribute('target');
                sendBtn.removeAttribute('rel');
            }

            document.body.classList.remove('overflow-hidden');
        }
    },

    whatsappModalConsent(e: Event) {
        const currentTarget = e.currentTarget as HTMLInputElement;
        if (currentTarget) {
            const modal = document.querySelector('.whatsapp-modal') as HTMLElement;
            if (modal) {
                const whatsappNumber = modal.dataset.phone;
                const whatsappText = modal.dataset.whatsappText;
                if (whatsappNumber) {
                    currentTarget.addEventListener('change', () => {
                        const sendBtn = document.querySelector('.whatsapp-modal-btn') as HTMLAnchorElement;
                        if (sendBtn) {
                            if (currentTarget.checked) {
                                currentTarget.classList.remove('is-invalid');
                                if (whatsappText) {
                                    sendBtn.href = "https://wa.me/" + whatsappNumber + "?text=" + encodeURIComponent(whatsappText);
                                } else {
                                    sendBtn.href = "https://wa.me/" + whatsappNumber;
                                }
                                sendBtn.setAttribute('target', '_blank');
                                sendBtn.setAttribute('rel', 'noopener');
                            } else {
                                sendBtn.href = "javascript:0";
                                sendBtn.removeAttribute('target');
                                sendBtn.removeAttribute('rel');
                            }
                        }
                    });
                }
            }
        }
    },

    whatsappModalSend(e: Event) {
        const currentTarget = e.currentTarget as HTMLElement;
        if (currentTarget) {
            const consent = document.getElementById('whatsapp-consent') as HTMLInputElement;
            if (consent) {
                if (consent.checked) {
                    consent.classList.remove('is-invalid');
                } else {
                    consent.classList.add('is-invalid');
                }
            }
        }
    }
});
