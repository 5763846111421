import { AlpineComponent } from "alpinejs";
import axios from "axios";
import { decode } from "html-entities";
import { merge as mergeObjects } from "lodash-es";
import ListItem from "./Item";
import Filter from "./Filter";
import Pagination from "./Pagination";
import Search from "./Search";
import { setHistoryState, getHistoryStateFromUrl, IHistoryStateProps } from "../../../utils/historyState";

export interface ListXDataContext<Item extends ListItem> {
    filter: Filter | null;
    filteredItems: Array<Item>;
    isLoading: boolean;
    items: Array<Item>;
    pagination: Pagination | null;
    search: Search | null;
    fetchItems: () => Promise<void>;
    fetchItemsContent: () => void;
    filterItems: () => void;
    getVisibleItems: () => Array<Item>;
    setHistoryState: () => void;

    created: () => void;
}

export type ListComponent<Item extends ListItem> = AlpineComponent<ListXDataContext<Item>>;

export interface ListOptions {
    getItemsUrl: string,
    getItemsContentUrl: string,
    pageSize: number,
}

const List = <Item extends ListItem>(options: ListOptions & IHistoryStateProps, filterData = {}): ListComponent<Item> => {
    return {
        // properties
        filter: null,
        filteredItems: [],
        isLoading: false,
        items: [],
        pagination: null,
        search: null,

        // constructor
        init() {
            // @ts-ignore
            this.filter = new Filter(filterData);
            this.pagination = new Pagination({
                pageSize: options.pageSize,
                onUpdate: (hasCurrentPageChanged) => {
                    if (hasCurrentPageChanged) {
                        let firstVisibleItem = this.$root.querySelector<HTMLElement>(".ce-latest-entries-prop-item-teaser");

                        if (firstVisibleItem) {
                            const rect = firstVisibleItem.getBoundingClientRect();
                            const scrollTop = window.scrollY || document.documentElement.scrollTop;

                            window.scrollTo({
                                top: rect.top + scrollTop - 80 - (this.$scrollOffset || 0),
                                behavior: "smooth"
                            });
                        }
                    }

                    this.fetchItemsContent();
                }
            });
            this.search = new Search();

            if (options.enableHistoryState) {
                mergeObjects(this.filter.data, getHistoryStateFromUrl());
            }

            ["filter.data", "search.results"].forEach((prop) => {
                this.$watch(prop, () => {
                    this.filterItems();
                    this.setHistoryState();
                });
            });

            this.created();

            this.fetchItems();
        },

        // methods
        fetchItems() {
            let items: Array<Item> = [];

            this.isLoading = true;
            return axios({
                url: options.getItemsUrl
            })
                .then((response) => {
                    if (response.data.data.length) {
                        items = response.data.data;
                    }
                })
                .catch((error) => {
                    console.error("Oops an error occurred: ", error);
                }).finally(() => {
                    // @ts-ignore
                    this.items = items;
                    this.filterItems();
                    this.isLoading = false;
                });
        },
        fetchItemsContent() {
            if (!options.getItemsContentUrl) {
                return;
            }
            const visibleItems = this.getVisibleItems();
            if (visibleItems.length) {
                axios.get(options.getItemsContentUrl, {
                    params: {
                        uids: visibleItems.filter((item: ListItem) => !item.content).map((item: ListItem) => item.id).join(",")
                    }
                })
                    .then((response) => {
                        response.data.forEach((responseItem: ListItem) => {
                            const index = this.items.findIndex((item: ListItem) => responseItem.uid === item.id);
                            this.items[index].content = decode(responseItem.content);
                        });
                    })
                    .catch((error) => {
                        console.error("Oops an error occurred: ", error);
                    });
            }
        },

        setHistoryState() {
            if (options.enableHistoryState && this.$el.id) {
                setHistoryState(this.$el.id, this.filter ? this.filter.data : {}, true);
            }
        },

        /**
         * Lifecycle methods
         */
        created() {
        },

        /**
         * This method is meant to be extended in specific component
         * to set up a filter logic for items
         */
        filterItems() {
            this.filteredItems = this.items;

            if (this.search) {
                // @ts-ignore
                this.filteredItems = this.search.filterItems(this.filteredItems);
            }

            if (this.filter && this.filter.data) {
                this.filteredItems = this.filteredItems.filter((item) => {
                    // for each filter property
                    for (const property in this.filter!.data) {
                        let filterPropertyValue = this.filter!.data[property];
                        let itemPropertyValue = item[property];

                        // if filter data property is empty, skip it
                        if ((!filterPropertyValue) ||
                            (Array.isArray(filterPropertyValue) && filterPropertyValue.length === 0)
                        ) {
                            continue;
                        }

                        // if item property has objects with uids, get flat list of uids
                        if (Array.isArray(itemPropertyValue) && itemPropertyValue.length > 0 && itemPropertyValue[0].uid) {
                            itemPropertyValue = itemPropertyValue.map((item: { uid: any }) => item.uid);
                        }

                        if (Array.isArray(filterPropertyValue) && Array.isArray(itemPropertyValue)) {
                            // if filter data property and item property are both arrays, check if at least one item property is in filter data property
                            if (!(itemPropertyValue as Array<any>).some((value) => (filterPropertyValue as Array<any>).includes(value))) {
                                return false;
                            }
                        } else if (Array.isArray(filterPropertyValue)) {
                            // if filter data property is an array and item property is not, check if item property is in filter data property
                            if (!filterPropertyValue.includes(itemPropertyValue)) {
                                return false;
                            }
                        } else if (Array.isArray(itemPropertyValue)) {
                            // if item property is an array and filter data property is not, check if filter data property is in item property
                            if (!(itemPropertyValue as Array<any>).includes(filterPropertyValue)) {
                                return false;
                            }
                        } else if (filterPropertyValue !== itemPropertyValue) {
                            // if filter data property and item property are not arrays, check if they are equal
                            return false;
                        }
                    }

                    return true;
                });
            }

            if (this.pagination) {
                this.pagination.setTotalItems(this.filteredItems.length);
                this.pagination.goToPage(1);
            }

            // Change the number of items in the filter info
            const filterInfos = document.querySelectorAll(".project-list-pages") as NodeListOf<HTMLElement>;
            if (filterInfos) {
                // foreach filter info
                filterInfos.forEach((filterInfo) => {
                    let current = filterInfo.querySelector(".current") as HTMLElement;
                    if (current) {
                        current.innerHTML = this.filteredItems.length.toString();
                    }
                    let total = filterInfo.querySelector(".total") as HTMLElement;
                    if (total) {
                        total.innerHTML = this.items.length.toString();
                    }
                });
            }
        },

        // getters
        // @ts-ignore
        getVisibleItems() {
            if (!this.pagination) {
                return this.filteredItems;
            }

            return this.filteredItems.slice(this.pagination.state.startIndex, this.pagination.state.endIndex + 1);
        }
    };
};

export default List;
