import { AlpineComponent } from 'alpinejs';

type ContactFormData = {
    getResults: (formElement: HTMLFormElement) => void;
    formResult: FormResult | null;
}

type FormResult = {
    success: boolean;
    errors?: { [key: string]: string };
};

export default ():AlpineComponent<ContactFormData> => ({
    formResult: null,

    getResults(formElement) {
        // eslint-disable-next-line
        let context = this;
        let data = new FormData(formElement);
        let xhr = new XMLHttpRequest();

        xhr.open(formElement.method, formElement.action);
        xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        xhr.send(data);
        xhr.onload = function() {
            if (xhr.readyState === xhr.DONE) {
                context.formResult = JSON.parse(xhr.response);

                // Preloader icon
                const submitButton = formElement.querySelector('.form-submit-btn');
                if (submitButton) {
                    submitButton.classList.add('loading');
                }

                if (context.formResult && context.formResult.success) {
                    // Success message
                    const formContent = formElement.querySelector('.ce-contact-form-content');
                    const succesMessage = formElement.querySelector('.ce-contact-form-success-message');
                    const errorBlock = formElement.querySelector('.ce-contact-form-error-message');
                    if (errorBlock) {
                        errorBlock.innerHTML = '';
                        errorBlock.classList.remove('active');
                    }
                    if (succesMessage) {
                        succesMessage.classList.add('active');
                    }
                    if (formContent) {
                        formContent.classList.add('submitted');
                    }
                } else {
                    // Handle errors
                    const errorBlock = formElement.querySelector('.ce-contact-form-error-message');
                    if (errorBlock) {
                        errorBlock.innerHTML = '';
                        if (context.formResult && context.formResult.errors) {
                            for (let error in context.formResult.errors) {
                                errorBlock.innerHTML += context.formResult.errors[error] + '<br>';
                            }
                        }
                        errorBlock.classList.add('active');
                    }
                }

                if (submitButton) {
                    submitButton.classList.remove('loading');
                }

                // Scroll to top of the form
                const screenWidth = window.innerWidth;
                let topOffset = 200;
                if (screenWidth < 992) {
                    topOffset = 100;
                }
                const formTop = formElement.getBoundingClientRect().top + window.scrollY;
                window.scrollTo({
                    top: formTop - topOffset,
                    behavior: 'smooth'
                });
            }
        };
    }
});
