import { AlpineComponent } from 'alpinejs';

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { gsap } from "gsap";

type PropertyHeroData = {
    anchorNavOpen: boolean,
    anchorNav: () => void,
    anchorNavPosition: () => void,
    toggleAnchorNav: () => void,
    scrollToContact: () => void,
    share: (title: string) => void,
    isCollapsedPrice: boolean
}

export default ():AlpineComponent<PropertyHeroData> => ({
    anchorNavOpen: false,
    isCollapsedPrice: false,

    init() {
        // Fancybox
        Fancybox.bind("[data-fancybox=\"plan\"]", {
            // Your custom options
        });

        // Anchor navigation
        this.anchorNav();
        this.anchorNavPosition();
        window.addEventListener('resize', () => {
            this.anchorNav();
            this.anchorNavPosition();
        });
    },

    toggleAnchorNav() {
        this.anchorNavOpen = !this.anchorNavOpen;
    },

    anchorNav() {
        const screenWidth = window.innerWidth;

        // Anchor navigation
        const navigation = this.$root.querySelector('.ce-property-hero-navigation-nav') as HTMLElement;
        if (navigation) {
            const navItems = navigation.querySelectorAll(".ce-property-hero-navigation-nav-item") as NodeListOf<HTMLElement>;
            if (navItems) {
                navItems.forEach((item) => {
                    // on click event
                    item.addEventListener("click", () => {
                        navItems.forEach((navItem) => {
                            navItem.classList.remove("active");
                        });
                        item.classList.add("active");

                        // Replace active item in submenu on mobile
                        if (screenWidth < 992) {
                            this.anchorNavOpen = false;

                            const currentItemHolder = this.$root.querySelector(".ce-property-hero-navigation-nav-current") as HTMLElement;
                            if (currentItemHolder) {
                                const currentItem = currentItemHolder.querySelector(".ce-property-hero-navigation-nav-item") as HTMLElement;
                                if (currentItem) {
                                    const submenu = this.$root.querySelector(".ce-property-hero-navigation-nav-submenu-inner") as HTMLElement;
                                    if (submenu) {
                                        submenu.appendChild(currentItem);
                                    }
                                }

                                currentItemHolder.innerHTML = ""
                                currentItemHolder.appendChild(item);
                            }
                        }

                        // About
                        if (step == "1") {
                            history.pushState("", document.title, window.location.pathname + window.location.search);
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                        }
                    });

                    // Scroll to sections
                    const step = item.dataset.step;

                    // Pictures
                    if (step == "2") {
                        const section = document.querySelector("section.ce-images-videos") as HTMLElement;
                        if (section) {
                            const sectionId = section.id;
                            if (sectionId && item instanceof HTMLAnchorElement) {
                                item.href = "#" + sectionId;
                            }
                        }
                    }
                    // Downloads
                    if (step == "3") {
                        const section = document.querySelector("section.ce-downloads") as HTMLElement;
                        if (section) {
                            const sectionId = section.id;
                            if (sectionId && item instanceof HTMLAnchorElement) {
                                item.href = "#" + sectionId;
                            }
                        }
                    }
                    // Contact
                    if (step == "4") {
                        const section = document.querySelector("section.ce-contact-teaser") as HTMLElement;
                        if (section) {
                            const sectionId = section.id;
                            if (sectionId && item instanceof HTMLAnchorElement) {
                                item.href = "#" + sectionId;
                            }
                        }
                    }
                });
            }
        }
    },

    anchorNavPosition() {
        let scrollAnchor = gsap.timeline();
        const screenWidth = window.innerWidth;
        let bottomOffset = 40
        if (screenWidth < 992) {
            bottomOffset = 0;
        }

        scrollAnchor.to('.ce-property-hero-navigation-inner', {
            scrollTrigger: {
                trigger: '.footer',
                start: 'top 100%-=' + bottomOffset,
                toggleActions: "play none none reset",
                markers: false,
                toggleClass: { targets: ".ce-property-hero-navigation", className: "ce-property-hero-navigation--hidden" }
            },
        });
    },

    scrollToContact() {
        const screenWidth = window.innerWidth;
        const contactElements = document.querySelectorAll("section.ce-contact-teaser") as NodeListOf<HTMLElement>;

        if (contactElements) {
            // foreach and get last element
            const lastElement = contactElements[contactElements.length - 1];
            if (lastElement) {
                let topOffset = 200;
                if (screenWidth < 992) {
                    topOffset = 100;
                }

                const elementPosition = lastElement.getBoundingClientRect().top + window.pageYOffset;
                const offsetPosition = elementPosition - topOffset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            }
        }
    },

    share(title: string) {
        const screenWidth = window.innerWidth;

        if (screenWidth < 992) {
            if (navigator.share) {
                navigator.share({
                    title: document.title,
                    text: title,
                    url: window.location.href
                })
                    .catch((error) => console.log('Error sharing', error));
            }
        } else {
            // toggle active class on clicked item
            const shareContainer = this.$root.querySelector('.share-container') as HTMLElement;
            if (shareContainer) {
                shareContainer.classList.toggle('active');
            }
        }
    },
});
