import { AlpineComponent } from 'alpinejs';

type VideoTextTeaserData = {}

export default ():AlpineComponent<VideoTextTeaserData> => ({
    init() {
        const playBtn = this.$root.querySelector('.ce-video-text-teaser-btn--play') as HTMLElement;
        if (playBtn) {
            playBtn.addEventListener('click', () => {
                const videoItem = this.$root.querySelector('.ce-video-text-teaser-media') as HTMLElement;
                if (videoItem) {
                    const videoType = videoItem.dataset.type;
                    if (videoType) {
                        if (videoType == 'mp4') {
                            const video = videoItem.querySelector('.ce-video-text-teaser--video') as HTMLVideoElement;
                            if (video) {
                                videoItem.classList.add('is-playing');
                                video.play();
                            }
                        } else if (videoType == 'youtube') {
                            const video = videoItem.querySelector('.ce-video-text-teaser--video') as HTMLIFrameElement;
                            if (video) {
                                let url = (video as HTMLElement).dataset.src;
                                url = url + "&autoplay=1&mute=1";
                                video.src = url;

                                videoItem.classList.add('is-playing');
                            }
                        } else if (videoType == 'vimeo') {
                            const video = videoItem.querySelector('.ce-video-text-teaser--video') as HTMLIFrameElement;
                            if (video) {
                                let url = (video as HTMLElement).dataset.src;
                                url = url + "&autoplay=1&muted=1";
                                video.src = url;

                                videoItem.classList.add('is-playing');
                            }
                        }
                    }
                }
            });
        }
    }
});
