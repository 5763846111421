import { AlpineComponent } from 'alpinejs';
import Swiper from 'swiper';
import { Autoplay } from 'swiper/modules';

type LocationsData = {
    swiperResize: (swiper: Swiper, swiperEl: Element) => void,
}

export default ():AlpineComponent<LocationsData> => ({
    init() {
        const swiperEl = this.$root.querySelector('.swiper') as HTMLElement;
        if (!swiperEl) {
            return;
        }

        let swiper = new Swiper(swiperEl, {
            autoplay: {
                delay: 2500,
                disableOnInteraction: true,
            },
            speed: 1000,
            loop: false,
            slidesPerView: 1.2,
            spaceBetween: 0,
            threshold: 10,
            centerInsufficientSlides: true,
            watchOverflow: true,
            followFinger: true,
            updateOnWindowResize: true,
            grabCursor: true,
            modules: [Autoplay],
            on: {
                slidesLengthChange: () => {
                    this.$lazyLoad.update();
                },
            }
        });

        // Enable swiper only for mobile
        let mobile = window.matchMedia('(min-width: 0px) and (max-width: 991px)');
        if (mobile.matches) {
            this.$lazyLoad.update();
            swiper.enable();
            swiper.update();
        } else {
            this.$lazyLoad.update();
            swiper.disable();
        }

        window.addEventListener('resize', () => {
            this.swiperResize(swiper, swiperEl);
        });
    },

    swiperResize(swiper: Swiper, swiperEl: Element) {
        // Enable swiper only for mobile on window resize
        let mobile = window.matchMedia('(min-width: 0px) and (max-width: 991px)');

        if (mobile.matches) {
            this.$lazyLoad.update();
            swiper.enable();
            swiper.update();
            swiper.autoplay.start();
        } else {
            this.$lazyLoad.update();
            swiper.disable();
            // Remove inline styles from swiper-wrapper insted of swiper.destroy();
            const swiperWrapper = swiperEl.querySelector('.swiper-wrapper') as HTMLElement;
            if (swiperWrapper) {
                swiperWrapper.removeAttribute('style');
            }
        }
    }
});
