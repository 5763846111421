import { AlpineComponent } from 'alpinejs';
import Swiper from 'swiper';
import { Autoplay, EffectCreative, Pagination } from 'swiper/modules';

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { gsap } from "gsap";

type ProjectHeroData = {
    anchorNavOpen: boolean,
    anchorNav: () => void,
    anchorNavPosition: () => void,
    toggleAnchorNav: () => void,
    swiperResize: (swiper: Swiper) => void,
    scrollToContact: () => void,
    share: (title: string) => void,
}

export default ():AlpineComponent<ProjectHeroData> => ({
    anchorNavOpen: false,

    init() {
        // Fancybox
        Fancybox.bind("[data-fancybox=\"gallery\"]", {
            // Your custom options
        });

        // Anchor navigation
        this.anchorNav();
        this.anchorNavPosition();
        window.addEventListener('resize', () => {
            this.anchorNav();
            this.anchorNavPosition();
        });

        // Swiper
        const swiperEl = this.$root.querySelector('.swiper') as HTMLElement;
        if (!swiperEl) {
            return;
        }

        new Swiper(swiperEl, {
            autoplay: {
                delay: 2500,
                disableOnInteraction: true,
            },
            effect: 'creative',
            creativeEffect: {
                prev: {
                    shadow: true,
                    translate: ['-20%', 0, -1],
                },
                next: {
                    translate: ['100%', 0, 0],
                },
            },
            speed: 1000,
            loop: true,
            autoHeight: false,
            spaceBetween: 0,
            threshold: 10,
            centerInsufficientSlides: true,
            watchOverflow: true,
            followFinger: true,
            updateOnWindowResize: true,
            grabCursor: true,
            modules: [Autoplay, EffectCreative, Pagination],
            pagination: {
                el: '.ce-project-hero-slide-pagination',
                clickable: true,
            },
            on: {
                slidesLengthChange: () => {
                    this.$lazyLoad.update();
                },
            }
        });
    },

    swiperResize(swiper: Swiper) {
        this.$lazyLoad.update();
        swiper.update();
    },

    toggleAnchorNav() {
        this.anchorNavOpen = !this.anchorNavOpen;
    },

    anchorNav() {
        const screenWidth = window.innerWidth;

        // Anchor navigation
        const navigation = this.$root.querySelector('.ce-project-hero-navigation-nav') as HTMLElement;
        if (navigation) {
            const navItems = navigation.querySelectorAll(".ce-project-hero-navigation-nav-item") as NodeListOf<HTMLElement>;
            if (navItems) {
                navItems.forEach((item) => {
                    // on click event
                    item.addEventListener("click", () => {
                        navItems.forEach((navItem) => {
                            navItem.classList.remove("active");
                        });
                        item.classList.add("active");

                        // Replace active item in submenu on mobile
                        if (screenWidth < 992) {
                            this.anchorNavOpen = false;

                            const currentItemHolder = this.$root.querySelector(".ce-project-hero-navigation-nav-current") as HTMLElement;
                            if (currentItemHolder) {
                                const currentItem = currentItemHolder.querySelector(".ce-project-hero-navigation-nav-item") as HTMLElement;
                                if (currentItem) {
                                    const submenu = this.$root.querySelector(".ce-project-hero-navigation-nav-submenu-inner") as HTMLElement;
                                    if (submenu) {
                                        submenu.appendChild(currentItem);
                                    }
                                }

                                currentItemHolder.innerHTML = ""
                                currentItemHolder.appendChild(item);
                            }
                        }

                        // About
                        if (step == "1") {
                            history.pushState("", document.title, window.location.pathname + window.location.search);
                            const section = document.querySelector("section.ce-features") as HTMLElement;
                            if (section) {
                                // scroll to section with 100px top offset
                                const elementPosition = section.getBoundingClientRect().top + window.pageYOffset;
                                const offsetPosition = elementPosition - 100;
                                window.scrollTo({
                                    top: offsetPosition,
                                    behavior: 'smooth'
                                });
                            }
                        }
                    });

                    // Scroll to sections
                    const step = item.dataset.step;

                    // Downloads
                    if (step == "2") {
                        const section = document.querySelector("section.ce-downloads") as HTMLElement;
                        if (section) {
                            const sectionId = section.id;
                            if (sectionId && item instanceof HTMLAnchorElement) {
                                item.href = "#" + sectionId;
                            }
                        } else {
                            const navItem = document.querySelector(".ce-project-hero-navigation-nav-item[data-step='2']") as HTMLElement;
                            if (navItem) {
                                navItem.classList.add("d-none");
                            }
                        }
                    }
                    // Apartments
                    if (step == "3") {
                        const section = document.querySelector("section.ce-property-list-element") as HTMLElement;
                        if (section) {
                            const sectionId = section.id;
                            if (sectionId && item instanceof HTMLAnchorElement) {
                                item.href = "#" + sectionId;
                            }
                        } else {
                            const navItem = document.querySelector(".ce-project-hero-navigation-nav-item[data-step='3']") as HTMLElement;
                            if (navItem) {
                                navItem.classList.add("d-none");
                            }
                        }
                    }
                    // Location
                    if (step == "4") {
                        const section = document.querySelector("section.ce-map") as HTMLElement;
                        if (section) {
                            const sectionId = section.id;
                            if (sectionId && item instanceof HTMLAnchorElement) {
                                item.href = "#" + sectionId;
                            }
                        } else {
                            const section2 = document.querySelector("section.ce-virtual-tour") as HTMLElement;
                            if (section2) {
                                const section2Id = section2.id;
                                if (section2Id && item instanceof HTMLAnchorElement) {
                                    item.href = "#" + section2Id;
                                }
                            } else {
                                const navItem = document.querySelector(".ce-project-hero-navigation-nav-item[data-step='4']") as HTMLElement;
                                if (navItem) {
                                    navItem.classList.add("d-none");
                                }
                            }
                        }
                    }
                    // Images
                    if (step == "5") {
                        const section = document.querySelector("section.ce-images-videos") as HTMLElement;
                        if (section) {
                            const sectionId = section.id;
                            if (sectionId && item instanceof HTMLAnchorElement) {
                                item.href = "#" + sectionId;
                            }
                        } else {
                            const navItem = document.querySelector(".ce-project-hero-navigation-nav-item[data-step='5']") as HTMLElement;
                            if (navItem) {
                                navItem.classList.add("d-none");
                            }
                        }
                    }
                    // Contact
                    if (step == "6") {
                        const section = document.querySelector("section.ce-contact-teaser") as HTMLElement;
                        if (section) {
                            const sectionId = section.id;
                            if (sectionId && item instanceof HTMLAnchorElement) {
                                item.href = "#" + sectionId;
                            }
                        } else {
                            const navItem = document.querySelector(".ce-project-hero-navigation-nav-item[data-step='6']") as HTMLElement;
                            if (navItem) {
                                navItem.classList.add("d-none");
                            }
                        }
                    }
                });
            }
        }
    },

    anchorNavPosition() {
        let scrollAnchor = gsap.timeline();
        const screenWidth = window.innerWidth;
        let bottomOffset = 40
        if (screenWidth < 992) {
            bottomOffset = 0;
        }

        scrollAnchor.to('.ce-project-hero-navigation-inner', {
            scrollTrigger: {
                trigger: '.footer',
                start: 'top 100%-=' + bottomOffset,
                toggleActions: "play none none reset",
                markers: false,
                toggleClass: { targets: ".ce-project-hero-navigation", className: "ce-project-hero-navigation--hidden" }
            },
        });
    },

    scrollToContact() {
        const screenWidth = window.innerWidth;
        const contactElements = document.querySelectorAll("section.ce-contact-teaser") as NodeListOf<HTMLElement>;

        if (contactElements) {
            // foreach and get last element
            const lastElement = contactElements[contactElements.length - 1];
            if (lastElement) {
                let topOffset = 200;
                if (screenWidth < 992) {
                    topOffset = 100;
                }

                const elementPosition = lastElement.getBoundingClientRect().top + window.pageYOffset;
                const offsetPosition = elementPosition - topOffset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            }
        }
    },

    share(title: string) {
        const screenWidth = window.innerWidth;

        if (screenWidth < 992) {
            if (navigator.share) {
                navigator.share({
                    title: document.title,
                    text: title,
                    url: window.location.href
                })
                    .catch((error) => console.log('Error sharing', error));
            }
        } else {
            // toggle active class on clicked item
            const shareContainer = this.$root.querySelector('.share-container') as HTMLElement;
            if (shareContainer) {
                shareContainer.classList.toggle('active');
            }
        }
    }
});
