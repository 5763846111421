import getObjectByPath from "lodash-es/get";
import setObjectByPath from "lodash-es/set";

export default class Filter {
    data: { [key: string]: any };
    initialData: { [key: string]: any };

    constructor(data: { [key: string]: any }) {
        this.initialData = JSON.parse(JSON.stringify(data));
        this.data = data;
    }

    resetFilter(property?: string) {
        if (property) {
            setObjectByPath(this.data, property, JSON.parse(JSON.stringify(getObjectByPath(this.initialData, property))));
        } else {
            Object.assign(this.data, JSON.parse(JSON.stringify(this.initialData)));
        }
    }
}
