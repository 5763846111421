import AOS from 'aos';

const DESKTOP_BREAKPOINT = 768;

export default function () {
    document.addEventListener('alpine:init', () => {
        AOS.init({
            duration: 500,
            once: true,
            disable: window.innerWidth < DESKTOP_BREAKPOINT,
        });
    });
}
