import { AlpineComponent } from 'alpinejs';
import { Collapse } from "bootstrap";
import {
    getHistoryState,
    setHistoryState
} from '../utils/historyState';

type CollapseData = {
    openedItems: string[],
    parent: string,
    scroll: boolean,
    toggle: boolean,
    openItemsFromHistoryState: () => void,
    scrollToOpenedItem: (item: HTMLElement) => void,
    toggleOpenedItems: (item: HTMLElement) => void,
}

export default (parent:string, scroll = true):AlpineComponent<CollapseData> => ({
    openedItems: [], // IDs of opened items
    parent,
    scroll,
    toggle: false,

    init() {
        this.$scrollOffset = 150;

        const collapseElements = Array.from(this.$root.querySelectorAll<HTMLElement>('.collapse'));
        if (collapseElements.length) {
            // init Collapse elements
            collapseElements.map((item) => {
                return new Collapse(item, {
                    parent: this.parent,
                    toggle: this.toggle
                });
            });

            // bind event listeners
            collapseElements.forEach((item) => {
                item.addEventListener('shown.bs.collapse', () => {
                    this.$lazyLoad.update();
                    this.toggleOpenedItems(item);
                    this.scrollToOpenedItem(item);
                });
                item.addEventListener('hidden.bs.collapse', () => {
                    this.toggleOpenedItems(item);
                });
            });

            // restore opened items from history state
            const historyStateAttr = this.$root.dataset.historyState;
            if (historyStateAttr) {
                if (historyStateAttr != 'false') {
                    this.openItemsFromHistoryState();
                }
            }

            // update lazyLoad - in case that there are some images used in Collapse items
            this.$lazyLoad.update();
        }
    },

    openItemsFromHistoryState() {
        const state = getHistoryState(this.parent);

        if (state && Array.isArray(state) && state.length) {
            for (const item of state) {
                this.openedItems.push(item);
                this.$root.querySelector(`[data-bs-target="#${item}"]`)?.classList.remove('collapsed');
                this.$root.querySelector(`#${item}`)?.classList.add('show');
            }
        }
    },

    scrollToOpenedItem(item: HTMLElement) {
        if (this.scroll) {
            const previousItem = item.previousElementSibling as HTMLElement | null;
            if (previousItem) {
                window.scrollTo({
                    top: previousItem.offsetTop - (this.$scrollOffset || 0),
                    behavior: 'smooth'
                });
            }
        }
    },

    toggleOpenedItems(item: HTMLElement) {
        if (!this.openedItems.includes(item.id)) {
            this.openedItems.push(item.id);
        } else {
            this.openedItems.splice(this.openedItems.indexOf(item.id), 1);
        }
        setHistoryState(this.parent, this.openedItems);
    }
});
