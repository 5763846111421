import { AlpineComponent } from 'alpinejs';
import axios from 'axios';

type DownloadsData = {}

export default ():AlpineComponent<DownloadsData> => ({
    init() {
        const siteLang = document.getElementsByTagName("html")[0].getAttribute("lang");

        const downloadItems = this.$root.querySelectorAll('.ce-downloads-item') as NodeListOf<HTMLElement>;
        if (downloadItems) {
            downloadItems.forEach((item) => {
                // Open/Close email address overlay
                const downloadButton = item.querySelector('.ce-downloads-item-mail') as HTMLElement;
                const closeButton = item.querySelector('.icon-close') as HTMLElement;
                const closeButtonMessage = item.querySelector('.icon-message-close') as HTMLElement;

                if (downloadButton) {
                    downloadButton.addEventListener('click', () => {
                        item.classList.add("active");
                    });
                }
                if (closeButton) {
                    closeButton.addEventListener('click', () => {
                        item.classList.remove("active");
                    });
                }
                if (closeButtonMessage) {
                    closeButtonMessage.addEventListener('click', () => {
                        item.classList.remove("active");

                        const messageOverlay = item.querySelector('.ce-downloads-item-mail-overlay-message') as HTMLElement;
                        if (messageOverlay) {
                            messageOverlay.classList.remove("active");
                        }

                        const itemOverlay = item.querySelector('.ce-downloads-item-mail-overlay') as HTMLElement;
                        if (itemOverlay) {
                            itemOverlay.classList.remove("inactive");
                        }
                    });
                }

                // Send mail logic
                const emailInput = item.querySelector('.form-control') as HTMLInputElement;
                if (emailInput) {
                    emailInput.addEventListener('input', () => {
                        const itemId = sendBtn.dataset.file;
                        if (itemId) {
                            if (emailInput.value) {
                                // Validate email
                                const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                                if (emailInput.value.match(validRegex)) {
                                    emailInput.classList.remove("is-invalid");
                                    emailInput.classList.add("is-valid");
                                } else {
                                    emailInput.classList.add("is-invalid");
                                }
                            } else {
                                emailInput.classList.add("is-invalid");
                            }
                        }
                    });
                }

                const sendBtn = item.querySelector('.ce-downloads-item-btn-send') as HTMLAnchorElement;
                if (sendBtn) {
                    sendBtn.addEventListener('click', () => {
                        const emailInput = item.querySelector('.form-control') as HTMLInputElement;
                        if (emailInput) {
                            const itemId = sendBtn.dataset.file;
                            if (itemId) {
                                if (emailInput.value) {
                                    // Validate email
                                    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                                    if (emailInput.value.match(validRegex)) {
                                        emailInput.classList.remove("is-invalid");
                                        emailInput.classList.add("is-valid");

                                        // Check checkbox
                                        const checkbox = item.querySelector('.form-check-input') as HTMLInputElement;
                                        if (checkbox) {
                                            if (checkbox.checked == true) {
                                                checkbox.classList.remove("is-invalid");
                                                emailInput.classList.add("is-valid");

                                                // Send mail via Axios
                                                let endpointUrl = "/send-file/" + emailInput.value + "/" + itemId;
                                                if (siteLang != 'de') {
                                                    endpointUrl = "/"  + siteLang + "/send-file/" + emailInput.value + "/" + itemId;
                                                }

                                                axios({
                                                    url: endpointUrl,
                                                })
                                                    .then((response) => {
                                                        const messageOverlay = item.querySelector('.ce-downloads-item-mail-overlay-message') as HTMLElement;
                                                        if (messageOverlay) {
                                                            messageOverlay.classList.add("active");

                                                            const itemOverlay = item.querySelector('.ce-downloads-item-mail-overlay') as HTMLElement;
                                                            if (itemOverlay) {
                                                                itemOverlay.classList.add("inactive");
                                                            }

                                                            if (response.data) {
                                                                const messageSuccess = messageOverlay.querySelector('.success') as HTMLElement;
                                                                if (messageSuccess) {
                                                                    messageSuccess.classList.remove("d-none");
                                                                }
                                                                const messageError = messageOverlay.querySelector('.error') as HTMLElement;
                                                                if (messageError) {
                                                                    messageError.classList.add("d-none");
                                                                }
                                                            } else {
                                                                const messageSuccess = messageOverlay.querySelector('.success') as HTMLElement;
                                                                if (messageSuccess) {
                                                                    messageSuccess.classList.add("d-none");
                                                                }
                                                                const messageError = messageOverlay.querySelector('.error') as HTMLElement;
                                                                if (messageError) {
                                                                    messageError.classList.remove("d-none");
                                                                }
                                                            }
                                                        }
                                                    })
                                                    .catch(() => {
                                                        const messageOverlay = item.querySelector('.ce-downloads-item-mail-overlay-message') as HTMLElement;
                                                        if (messageOverlay) {
                                                            messageOverlay.classList.add("active");

                                                            const messageSuccess = messageOverlay.querySelector('.success') as HTMLElement;
                                                            if (messageSuccess) {
                                                                messageSuccess.classList.add("d-none");
                                                            }
                                                            const messageError = messageOverlay.querySelector('.error') as HTMLElement;
                                                            if (messageError) {
                                                                messageError.classList.remove("d-none");
                                                            }
                                                        }
                                                    });
                                            } else {
                                                checkbox.classList.add("is-invalid");
                                            }
                                        }
                                    } else {
                                        emailInput.classList.remove("is-valid");
                                        emailInput.classList.add("is-invalid");
                                    }
                                } else {
                                    emailInput.classList.remove("is-valid");
                                    emailInput.classList.add("is-invalid");
                                }
                            }
                        }
                    });
                }
            });
        }
    },
});
