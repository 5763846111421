import { AlpineComponent } from "alpinejs";
import {
    default as AjaxList,
    ListXDataContext as AjaxListXDataContext,
    ListOptions as AjaxListOptions
} from '../../components/AjaxList/Classes/List';
import ListItem from "../../components/AjaxList/Classes/Item";
import { IHistoryStateProps } from "../../utils/historyState";

export type ListComponent = AlpineComponent<AjaxListXDataContext<ListItem> & {
    activeFilters: { uid: string, label: string }[];
    filterOpen: boolean;
    heroOpened: boolean;
    projects: { [key: string]: Project };
    toggleFilter: () => void;

    changeSorting: (sortProperty: string) => void;
    toggleActiveClass: (sortProperty: string) => void;
    toggleSortDataAttribute: (sortProperty: string) => void;
    resetFilter: () => void;

    initialSelectValues: () => void;
    selectLastAvailableOption: (selectElement: HTMLSelectElement, currentValue: number) => void;
    updateURLParameter: (param: string, value: string) => void;

    filterFlatSize: (changedSelect: string) => void;
    filterPrice: (changedSelect: string) => void;
    filterRooms: (changedSelect: string) => void;
}>;

interface Project {
    projectId: string;
    projectTitle: string;
    projectSubtitle: string;
    projectUrl: string;
    projectStates: any[];
    projectImage: string;
    properties: any[];
}

interface Property {
    projectId: string;
    projectTitle: string;
    projectSubtitle: string;
    projectUrl: string;
    projectStates: any[];
    projectImage: string;
}

export type ListOptions = AjaxListOptions & IHistoryStateProps;

const List = (options: ListOptions, filterData = {}):ListComponent => {
    let sorting = {
        property: '',
        order: 'ASC'
    };

    return {
        ...AjaxList(options, filterData),
        activeFilters: [],
        filterOpen: false,
        heroOpened: true,

        // getters
        get projects() {
            // @ts-ignore
            return this.getVisibleItems().reduce((projects: { [key: string]: Project }, property: Property) => {
                if (!projects[property.projectId]) {
                    projects[property.projectId] = {
                        projectId: property.projectId,
                        projectTitle: property.projectTitle,
                        projectSubtitle: property.projectSubtitle,
                        projectUrl: property.projectUrl,
                        projectStates: property.projectStates,
                        projectImage: property.projectImage,
                        properties: []
                    };
                }

                // Create a copy of the property without the projectId
                const { projectId, projectTitle, projectSubtitle, projectUrl, projectStates, projectImage, ...propertyWithoutProjectInfo } = property;

                // Add the property without projectId to the project's properties array
                projects[property.projectId].properties.push(propertyWithoutProjectInfo);

                return projects;
            }, {});
        },

        created() {
            // Hero search state
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            this.heroOpened = !urlParams.has('federal_states');

            // Initial range select values
            this.initialSelectValues();
        },

        toggleFilter() {
            this.filterOpen = !this.filterOpen;
            document.body.classList.toggle('filter-opened');
        },

        changeSorting(sortProperty) {
            if (sorting.property === 'title') {
                // sort items by title within projects
                // this.items.sort((item1, item2) => {
                //     if (item1.title > item2.title) {
                //         return 1;
                //     } else if (item1.title < item2.title) {
                //         return -1;
                //     } else {
                //         return 0;
                //     }
                // });
            }

            if (sorting.property === sortProperty) {
                sorting.order = sorting.order === 'ASC' ? 'DESC' : 'ASC';
            } else {
                sorting.property = sortProperty;
                sorting.order = 'DESC';
            }
            this.toggleActiveClass(sortProperty);
            this.toggleSortDataAttribute(sortProperty);
            this.filterItems();
        },

        toggleActiveClass(sortProperty) {
            const sortingButtons = this.$root.querySelectorAll('.sorting-item') as NodeListOf<HTMLElement>;
            sortingButtons.forEach(button => {
                button.classList.toggle('active', button.getAttribute('data-sort-property') === sortProperty);
            });
        },

        toggleSortDataAttribute(sortProperty) {
            const sortingButtons = this.$root.querySelectorAll('.sorting-item') as NodeListOf<HTMLElement>;
            sortingButtons.forEach(button => {
                if (button.getAttribute('data-sort-property') === sortProperty) {
                    button.setAttribute('data-sort', sorting.order);
                } else {
                    button.removeAttribute('data-sort');
                }
            });
        },

        resetFilter() {
            // Reset sorting
            sorting = {
                property: '',
                order: 'ASC'
            };

            // Remove active class from all sorting buttons
            const sortingButtons = this.$root.querySelectorAll('.sorting-item') as NodeListOf<HTMLElement>;
            sortingButtons.forEach(button => {
                button.classList.remove('active');
                button.removeAttribute('data-sort');
            });

            // Remove all URL parameters except federal_states
            const url = new URL(window.location.href);
            const params = new URLSearchParams(url.search);
            const federalStates = params.get('federal_states');
            // @ts-ignore
            params.forEach((value, key) => {
                if (key !== 'federal_states') {
                    params.delete(key);
                }
            });
            if (federalStates) {
                params.set('federal_states', federalStates);
            }
            url.search = params.toString();
            window.history.replaceState(null, '', decodeURIComponent(url.toString()));

            // Reload page
            location.reload();
        },

        filterItems() {
            // // sort first by projectTitle then by title
            // this.items.sort((item1, item2) => {
            //     if (item1.projectTitle > item2.projectTitle) {
            //         return 1;
            //     } else if (item1.projectTitle < item2.projectTitle) {
            //         return -1;
            //     } else {
            //         return 0;
            //     }
            // });
            //
            // // Sort items by Top number in ascending order
            // this.items.sort((item1, item2) => {
            //     const title1 = String(item1.title).match(/\d+|\D+/g);
            //     const title2 = String(item2.title).match(/\d+|\D+/g);
            //
            //     if (title1 && title2) {
            //         for (let i = 0; i < Math.max(title1.length, title2.length); i++) {
            //             if (title1[i] !== title2[i]) {
            //                 const num1 = parseInt(title1[i], 10);
            //                 const num2 = parseInt(title2[i], 10);
            //
            //                 if (!isNaN(num1) && !isNaN(num2)) {
            //                     return num1 - num2;
            //                 } else {
            //                     return title1[i].localeCompare(title2[i]);
            //                 }
            //             }
            //         }
            //     }
            //
            //     return 0;
            // });

            // Sorting
            this.items.sort((item1, item2) => {
                const title1 = String(item1[sorting.property]).match(/\d+|\D+/g);
                const title2 = String(item2[sorting.property]).match(/\d+|\D+/g);

                if (title1 && title2) {
                    for (let i = 0; i < Math.max(title1.length, title2.length); i++) {
                        if (title1[i] !== title2[i]) {
                            const num1 = parseInt(title1[i], 10);
                            const num2 = parseInt(title2[i], 10);

                            if (!isNaN(num1) && !isNaN(num2)) {
                                return sorting.order === 'ASC' ? num1 - num2 : num2 - num1;
                            } else {
                                return sorting.order === 'ASC' ? title1[i].localeCompare(title2[i]) : title2[i].localeCompare(title1[i]);
                            }
                        }
                    }
                }

                return 0;
            });

            // Set filtered items
            this.filteredItems = this.items;

            if (this.search) {
                // @ts-ignore
                this.filteredItems = this.search.filterItems(this.filteredItems);
            }

            if (this.filter && this.filter.data) {
                this.filteredItems = this.filteredItems.filter((item) => {
                    // for each filter property
                    for (const property in this.filter!.data) {
                        let filterPropertyValue = this.filter!.data[property];

                        // if filter data property is empty, skip it
                        if ((!filterPropertyValue) ||
                            (Array.isArray(filterPropertyValue) && filterPropertyValue.length === 0)
                        ) {
                            continue;
                        }

                        // Federal states
                        if (property == 'federal_states') {
                            let filteredValue = filterPropertyValue;
                            let itemValue = item.federal_states;
                            let isInRange = false;
                            const includesAny = (arr: any[], values: any[],) => values.some(v => arr.includes(v));
                            if (includesAny(itemValue, filteredValue)) {
                                isInRange = true;
                            }
                            if (!isInRange) return false;
                        }

                        // House
                        if (property == 'house') {
                            let filteredValue = filterPropertyValue;
                            let itemValue = item.house;
                            let isInRange = false;
                            const includesAny = (arr: any[], values: any[],) => values.some(v => arr.includes(v));
                            if (includesAny(itemValue, filteredValue)) {
                                isInRange = true;
                            }
                            // const includesAll = (arr: any[], values: any[]) => values.every(v => arr.includes(v));
                            // if (includesAll(itemValue, filteredValue)) {
                            //     isInRange = true;
                            // }
                            if (!isInRange) return false;
                        }

                        // States
                        if (property == 'states') {
                            let filteredValue = filterPropertyValue;
                            let itemValue = item.states;
                            let isInRange = false;
                            if (itemValue.includes(filteredValue)) {
                                isInRange = true;
                            }
                            if (!isInRange) return false;
                        }

                        // Flat size
                        if (property == 'flat_size_from') {
                            let filteredValue = parseInt(filterPropertyValue);
                            let itemValue = item.flat_size[0];
                            let isInRange = false;
                            if (itemValue >= filteredValue) {
                                isInRange = true;
                            }
                            if (!isInRange) return false;
                        }
                        if (property == 'flat_size_to') {
                            let filteredValue = parseInt(filterPropertyValue);
                            let itemValue = item.flat_size[0];
                            let isInRange = false;
                            if (itemValue <= filteredValue) {
                                isInRange = true;
                            }
                            if (!isInRange) return false;
                        }

                        // Price
                        if (property == 'price_from') {
                            let filteredValue = parseInt(filterPropertyValue);
                            let itemValue = item.price[0];
                            let isInRange = false;
                            if (itemValue >= filteredValue) {
                                isInRange = true;
                            }
                            if (!isInRange) return false;
                        }
                        if (property == 'price_to') {
                            let filteredValue = parseInt(filterPropertyValue);
                            let itemValue = item.price[0];
                            let isInRange = false;
                            if (itemValue <= filteredValue) {
                                isInRange = true;
                            }
                            if (!isInRange) return false;
                        }

                        // Rooms
                        if (property == 'rooms_from') {
                            let filteredValue = parseInt(filterPropertyValue);
                            let itemValue = item.rooms[0];
                            let isInRange = false;
                            if (itemValue >= filteredValue) {
                                isInRange = true;
                            }
                            if (!isInRange) return false;
                        }
                        if (property == 'rooms_to') {
                            let filteredValue = parseInt(filterPropertyValue);
                            let itemValue = item.rooms[0];
                            let isInRange = false;
                            if (itemValue <= filteredValue) {
                                isInRange = true;
                            }
                            if (!isInRange) return false;
                        }

                        // Floor
                        if (property == 'floor_num') {
                            let filteredValue = filterPropertyValue;
                            let itemValue = item.floor_num;
                            let isInRange = false;
                            if (itemValue.includes(filteredValue)) {
                                isInRange = true;
                            }
                            if (!isInRange) return false;
                        }

                        // Additions
                        if (property == 'additions') {
                            let filteredValue = filterPropertyValue;
                            let itemValue = item.additions;
                            let isInRange = false;
                            const includesAny = (arr: any[], values: any[],) => values.some(v => arr.includes(v));
                            if (includesAny(itemValue, filteredValue)) {
                                isInRange = true;
                            }
                            // const includesAll = (arr: any[], values: any[]) => values.every(v => arr.includes(v));
                            // if (includesAll(itemValue, filteredValue)) {
                            //     isInRange = true;
                            // }
                            if (!isInRange) return false;
                        }
                    }

                    return true;
                });
            }

            if (this.pagination) {
                this.pagination.setTotalItems(this.filteredItems.length);
                this.pagination.goToPage(1);
            }

            // Change the number of items in the filter info
            const filterInfos = document.querySelectorAll(".project-list-pages") as NodeListOf<HTMLElement>;
            if (filterInfos) {
                // foreach filter info
                filterInfos.forEach((filterInfo) => {
                    let current = filterInfo.querySelector(".current") as HTMLElement;
                    if (current) {
                        current.innerHTML = this.filteredItems.length.toString();
                    }
                    let total = filterInfo.querySelector(".total") as HTMLElement;
                    if (total) {
                        total.innerHTML = this.items.length.toString();
                    }
                });
            }
        },

        initialSelectValues() {
            // If there is federal_states attribute in url preset range selects max values
            if (window.location.search.includes('federal_states')) {
                const rangeToSelects = this.$root.querySelectorAll('.range-select-to') as NodeListOf<HTMLSelectElement>;
                if (rangeToSelects) {
                    rangeToSelects.forEach((rangeToSelect) => {
                        const name = rangeToSelect.getAttribute('name');
                        if (name) {
                            Array.from(rangeToSelect.options).forEach((option) => {
                                if (option.dataset.last) {
                                    if (this.filter?.data) {
                                        this.filter.data[name] = option.value;
                                    }
                                }
                            });
                        }
                    });
                }
            }
        },

        selectLastAvailableOption(selectElement) {
            let availableOptions = Array.from(selectElement.options).filter(option => !option.disabled);

            if (availableOptions.length > 0) {
                let lastAvailableOption = availableOptions[availableOptions.length - 1];

                // Check if the currently selected option meets the conditions
                if (!selectElement.options[selectElement.selectedIndex].disabled) {
                    // Keep the current selection if it's valid
                    this.updateURLParameter(selectElement.name, selectElement.value);
                } else {
                    // Otherwise, select the last available option
                    selectElement.value = lastAvailableOption.value;
                    this.updateURLParameter(selectElement.name, lastAvailableOption.value);
                }
            }
        },
        updateURLParameter(param, value) {
            const url = new URL(window.location.href);
            url.searchParams.set(param, value);
            window.history.replaceState(null, '', decodeURIComponent(url.toString()));
        },
        filterFlatSize(changedSelect) {
            const selectFrom = this.$root.querySelector('#project-list-filter-flat_size_from') as HTMLSelectElement;
            const selectTo = this.$root.querySelector('#project-list-filter-flat_size_to') as HTMLSelectElement;

            const selectedFrom = parseInt(selectFrom.value);
            const selectedTo = parseInt(selectTo.value);

            if (changedSelect === 'from') {
                Array.from(selectTo.options).forEach(option => {
                    if (parseInt(option.value) < selectedFrom) {
                        option.disabled = true;
                    } else {
                        option.disabled = false;
                    }
                });
                // Select the last available option in "rooms_to"
                this.selectLastAvailableOption(selectTo, selectedTo);
            } else if (changedSelect === 'to') {
                Array.from(selectFrom.options).forEach(option => {
                    if (parseInt(option.value) > selectedTo) {
                        option.disabled = true;
                    } else {
                        option.disabled = false;
                    }
                });
                // Select the last available option in "rooms_from"
                this.selectLastAvailableOption(selectFrom, selectedFrom);
            }
        },
        filterPrice(changedSelect) {
            const selectFrom = this.$root.querySelector('#project-list-filter-price_from') as HTMLSelectElement;
            const selectTo = this.$root.querySelector('#project-list-filter-price_to') as HTMLSelectElement;

            const selectedFrom = parseInt(selectFrom.value);
            const selectedTo = parseInt(selectTo.value);

            if (changedSelect === 'from') {
                Array.from(selectTo.options).forEach(option => {
                    if (parseInt(option.value) < selectedFrom) {
                        option.disabled = true;
                    } else {
                        option.disabled = false;
                    }
                });
                // Select the last available option in "rooms_to"
                this.selectLastAvailableOption(selectTo, selectedTo);
            } else if (changedSelect === 'to') {
                Array.from(selectFrom.options).forEach(option => {
                    if (parseInt(option.value) > selectedTo) {
                        option.disabled = true;
                    } else {
                        option.disabled = false;
                    }
                });
                // Select the last available option in "rooms_from"
                this.selectLastAvailableOption(selectFrom, selectedFrom);
            }
        },
        filterRooms(changedSelect) {
            const selectFrom = this.$root.querySelector('#project-list-filter-rooms_from') as HTMLSelectElement;
            const selectTo = this.$root.querySelector('#project-list-filter-rooms_to') as HTMLSelectElement;

            const selectedFrom = parseInt(selectFrom.value);
            const selectedTo = parseInt(selectTo.value);

            if (changedSelect === 'from') {
                Array.from(selectTo.options).forEach(option => {
                    if (parseInt(option.value) < selectedFrom) {
                        option.disabled = true;
                    } else {
                        option.disabled = false;
                    }
                });
                // Select the last available option in "rooms_to"
                this.selectLastAvailableOption(selectTo, selectedTo);
            } else if (changedSelect === 'to') {
                Array.from(selectFrom.options).forEach(option => {
                    if (parseInt(option.value) > selectedTo) {
                        option.disabled = true;
                    } else {
                        option.disabled = false;
                    }
                });
                // Select the last available option in "rooms_from"
                this.selectLastAvailableOption(selectFrom, selectedFrom);
            }
        },
    }
}

export default List;
