import { AlpineComponent } from 'alpinejs';
import Swiper from 'swiper';
import { Autoplay } from 'swiper/modules';

type LatestEntriesData = {
    swiperResize: (swiper: Swiper, swiperEl: Element) => void,
}

export default ():AlpineComponent<LatestEntriesData> => ({
    init() {
        const swiperEl = this.$root.querySelector('.swiper') as HTMLElement;
        if (!swiperEl) {
            return;
        }

        let swiper = new Swiper(swiperEl, {
            autoplay: {
                delay: 2500,
                disableOnInteraction: true,
            },
            speed: 1000,
            loop: false,
            slidesPerView: 1.25,
            spaceBetween: 12,
            threshold: 10,
            centerInsufficientSlides: true,
            watchOverflow: true,
            followFinger: true,
            updateOnWindowResize: true,
            grabCursor: true,
            modules: [Autoplay],
            on: {
                slidesLengthChange: () => {
                    this.$lazyLoad.update();
                },
            }
        });

        // Enable swiper only for mobile
        let mobile = window.matchMedia('(min-width: 992px)');
        if (mobile.matches) {
            this.$lazyLoad.update();
            swiper.disable();
            // Remove inline styles from swiper-wrapper insted of swiper.destroy();
            const swiperWrapper = swiperEl.querySelector('.swiper-wrapper') as HTMLElement;
            if (swiperWrapper) {
                swiperWrapper.removeAttribute('style');
            }
        } else {
            this.$lazyLoad.update();
            swiper.enable();
            swiper.update();
        }

        window.addEventListener('resize', () => {
            this.swiperResize(swiper, swiperEl);
        });

        // One slide fix size
        const swiperWrapper = swiperEl.querySelector('.swiper-wrapper') as HTMLElement;
        if (swiperWrapper) {
            const slides = swiperWrapper.querySelectorAll('.swiper-slide');
            const slidesCount = slides.length;
            if (slidesCount == 1) {
                const element = this.$root as HTMLElement;
                if (element) {
                    element.classList.add('ce-latest-entries--full');
                }
            }
        }
    },

    swiperResize(swiper: Swiper, swiperEl: Element) {
        // Enable swiper only for mobile on window resize
        let mobile = window.matchMedia('(min-width: 992px)');

        if (mobile.matches) {
            this.$lazyLoad.update();
            swiper.disable();
            // Remove inline styles from swiper-wrapper insted of swiper.destroy();
            const swiperWrapper = swiperEl.querySelector('.swiper-wrapper') as HTMLElement;
            if (swiperWrapper) {
                swiperWrapper.removeAttribute('style');
            }
        } else {
            this.$lazyLoad.update();
            swiper.enable();
            swiper.update();
            swiper.autoplay.start();
        }

        // One slide fix size
        const swiperWrapper = swiperEl.querySelector('.swiper-wrapper') as HTMLElement;
        if (swiperWrapper) {
            const slides = swiperWrapper.querySelectorAll('.swiper-slide');
            const slidesCount = slides.length;
            if (slidesCount == 1) {
                const element = this.$root as HTMLElement;
                if (element) {
                    element.classList.add('ce-latest-entries--full');
                }
            }
        }
    }
});
