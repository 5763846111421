import { AlpineComponent } from 'alpinejs';


type FactsFiguresData = {}

export default ():AlpineComponent<FactsFiguresData> => ({
    init() {
        this.$lazyLoad.update();

        // Animate numbers
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const elNumbers = entry.target.querySelectorAll('.ce-facts-figures-facts-item-number-val');
                    if (elNumbers) {
                        elNumbers.forEach((counter) => {
                            const numberDataVal = counter.getAttribute('data-number') ?? '0';

                            function animateValue(obj: HTMLElement, start: number, end: number, duration: number) {
                                let startTimestamp: number | null = null;
                                const step = (timestamp: number) => {
                                    if (!startTimestamp) startTimestamp = timestamp;
                                    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
                                    obj.innerHTML = Math.floor(progress * (end - start) + start).toString();
                                    if (progress < 1) {
                                        window.requestAnimationFrame(step);
                                    } else {
                                        // after animation end
                                        setTimeout(() => {
                                            obj.innerHTML = numberDataVal;
                                            // add thousand separator
                                            obj.innerHTML = obj.innerHTML.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                                        }, 500);
                                    }
                                };
                                window.requestAnimationFrame(step);
                            }

                            const start = +counter.getAttribute('data-number')! || 0;
                            const end = +((counter as HTMLElement).innerText || 0);
                            animateValue(counter as HTMLElement, end, start, 3000);
                        });

                        observer.disconnect();
                    }
                }
            });
        }, { rootMargin: '-50% 0%' });

        new Set([
            document.querySelector('.ce-facts-figures'),
        ]).forEach((element) => {
            if (element) {
                observer.observe(element);
            }
        });
    }
});
