import axios from 'axios';
import debounce from 'debounce';

import Item from './Item';

export interface SearchResultData {
  uid: number
}

export default class AjaxListSearch {
  readonly QUERY_LENGTH_THRESHOLD = 3;

  fetchResultsDebounced: (form: HTMLFormElement) => void;
  isLoading = false;
  lastQuery = '';
  query = '';
  results: Array<SearchResultData> = [];

  constructor() {
    this.fetchResultsDebounced = debounce(this.fetchResults, 300);
  }

  fetchResults(form: HTMLFormElement): void {
    if (!form || !form.action) return;

    if (this.lastQuery === this.query) return;

    if (this.query.length >= this.QUERY_LENGTH_THRESHOLD) {
      this.isLoading = true;

      let formData = new FormData(form);
      let solrQuery = this.query;

      let searchOriginal = axios.post(form.action, formData);

      solrQuery = solrQuery.split(" ").map(item => "*" + item + "*").join(" ");
      formData.set('q', solrQuery);
      let searchWildcards = axios.post(form.action, formData);

      Promise.all([searchOriginal, searchWildcards])
        .then((responses: Array<{ data: Array<SearchResultData> }>) => {
          const results: Array<SearchResultData> = [];

          responses.forEach(response => results.push(...response.data));
          this.results = results;
        })
        .catch((error) => {
          console.error("Oops an error occurred: ", error);
        })
        .then(() => {
          this.isLoading = false;
        });
    } else {
      if (this.lastQuery.length >= this.QUERY_LENGTH_THRESHOLD) {
        this.results = [];
      }
    }

    this.lastQuery = this.query;
  }

  filterItems(items: Array<Item>): Array<Item> {
    if (this.query.length < this.QUERY_LENGTH_THRESHOLD) {
      return items;
    }

    return items.filter(item => this.results.find(resultItem => item.uid === resultItem.uid));
  }
}
