const locale = 'en';

const messages = {
  en: {
    cookies: {
      button: {
        acceptAll: 'Accept All',
        confirm: 'Confirm'
      },
      info: {
        disabled: 'Please enable cookies to view this content'
      }
    },
  }
};

// finally, pass them to AlpineI18n:
document.addEventListener("alpine-i18n:ready", function () {
  window.AlpineI18n.create(locale, messages);
});
