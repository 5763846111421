export default function () {
    document.addEventListener('alpine:init', () => {
        // eslint-disable-next-line
        if (navigator.userAgent.match(/(Mozilla\/5\.0 \(Linux; Android 11; moto g power \(2022\)\) AppleWebKit\/537\.36 \(KHTML, like Gecko\) Chrome\/109\.0.0.0 Mobile Safari\/537\.36)|(Mozilla\/5\.0 \(Macintosh; Intel Mac OS X 10_15_7\) AppleWebKit\/537\.36 \(KHTML, like Gecko\) Chrome\/109\.0\.0\.0 Safari\/537\.36)|(Speed Insights)|(Chrome-Lighthouse)|(PSTS[\d\.]+)/) ) {
            const openingAnimation = document.querySelector('.start-animation') as HTMLElement;
            if (openingAnimation) {
                openingAnimation.style.display = 'none';
            }
        } else {
            if (!localStorage.getItem('animation-played')) {
                document.body.classList.add('is-animating');
                localStorage.setItem('animation-played', 'true');

                setTimeout(function() {
                    document.body.classList.remove('is-animating');
                }, 4500);
            }

            // set localStorage item "animation-played" to true if not exists
            // setTimeout(function() {
            //     if (!localStorage.getItem('animation-played')) {
            //         localStorage.setItem('animation-played', 'true');
            //         document.body.classList.add('is-animating');
            //     }
            // }, 5000);

            // if localStorage item "animation-played" is true, remove "is-animating" class from body
            // if (localStorage.getItem('animation-played') === 'true') {
            //     document.body.classList.remove('is-animating');
            // }
        }
    });
}
