//import '../../scss/app.scss';

import Alpine from 'alpinejs';
import AlpineI18n from 'alpinejs-i18n';
import AsyncAlpine from 'async-alpine';

import Collapse from './components/Collapse';
import Header from './components/Header';
import Downloads from './components/Downloads';
import ImageTextTeaser from './components/ImageTextTeaser';
import FactsFigures from './components/FactsFigures';
import TeamMembers from './components/TeamMembers';
import Locations from './components/Locations';
import ImagesVideos from './components/ImagesVideos';
import VideoTextTeaser from './components/VideoTextTeaser';
import ProjectHero from './components/ProjectHero';
import PropertyHero from './components/PropertyHero';
import SearchButton from './components/SearchButton';
import LatestEntries from './components/LatestEntries';
import WhatsappModal from './components/WhatsappModal';

import ContactForm from './components/ContactForm';
import PrebookingForm from './components/PrebookingForm';

import ProjectsOverview from './ProjectsOverview';
import PropertiesOverview from './PropertiesOverview';
import PropertyList from './PropertyList';

import 'dom-slider';
import Animation from './plugins/animation';
import AOS from './plugins/aos';
import collapse from "@alpinejs/collapse";
import DetectLoading from './plugins/detect-loading';
import LazyLoad from './plugins/lazyload';

import './utils/i18n';

AsyncAlpine.init(Alpine);
AsyncAlpine.start();

Alpine.data('Collapse', Collapse);
Alpine.data('Header', Header);
Alpine.data('Downloads', Downloads);
Alpine.data('ImageTextTeaser', ImageTextTeaser);
Alpine.data('FactsFigures', FactsFigures);
Alpine.data('TeamMembers', TeamMembers);
Alpine.data('Locations', Locations);
Alpine.data('ImagesVideos', ImagesVideos);
Alpine.data('VideoTextTeaser', VideoTextTeaser);
Alpine.data('ProjectHero', ProjectHero);
Alpine.data('PropertyHero', PropertyHero);
Alpine.data('SearchButton', SearchButton);
Alpine.data('LatestEntries', LatestEntries);
Alpine.data('WhatsappModal', WhatsappModal);

Alpine.data('ContactForm', ContactForm);
Alpine.data('PrebookingForm', PrebookingForm);

Alpine.data('ProjectsOverview', ProjectsOverview);
Alpine.data('PropertiesOverview', PropertiesOverview);
Alpine.data('PropertyList', PropertyList);

Alpine.plugin(Animation);
Alpine.plugin(AlpineI18n);
Alpine.plugin(AOS);
Alpine.plugin(collapse);
Alpine.plugin(DetectLoading);
Alpine.plugin(LazyLoad);

window.Alpine = Alpine;
Alpine.start();
