import { AlpineComponent } from 'alpinejs';

type SearchButtonData = {
    filterNum: () => void,
    filterPropCount: string;
    heroFilterSelects: () => void,
    heroSearch: (event: Event) => void,
    scrollToContent: () => void,
    projectPage: string,
    propertyPage: string,
}

export default ():AlpineComponent<SearchButtonData> => ({
    filterPropCount: "0",
    projectPage: "",
    propertyPage: "",

    init() {
        this.heroFilterSelects();

        this.projectPage = this.$root.dataset.projectpage ? this.$root.dataset.projectpage : "";
        this.propertyPage = this.$root.dataset.propertypage ? this.$root.dataset.propertypage : "";

        // Show/hide select groups on Land filter change
        const landSelect = this.$root.querySelector('#filter_land') as HTMLSelectElement;
        if (landSelect) {
            landSelect.addEventListener('change', () => {
                this.heroFilterSelects();
            });
        }
        const ortSelect = this.$root.querySelector('#filter_ort') as HTMLSelectElement;
        if (ortSelect) {
            ortSelect.addEventListener('change', () => {
                const dataCount = ortSelect.selectedOptions[0].dataset.count;
                if (dataCount) {
                    this.filterPropCount = dataCount;
                    this.filterNum();
                }
            });
        }
    },

    filterNum() {
        const filterNum = this.$root.querySelector('.ce-search-hero-filter-submit-num') as HTMLElement;
        if (filterNum) {
            filterNum.innerHTML = this.filterPropCount;
        }
    },

    heroFilterSelects() {
        const landSelect = this.$root.querySelector('#filter_land') as HTMLSelectElement;
        const ortSelect = this.$root.querySelector('#filter_ort') as HTMLSelectElement;
        if (landSelect && ortSelect) {
            // remove all options from ort select
            ortSelect.options.length = 0;

            const tempSelect = this.$root.querySelector('.ce-search-hero-filter-selects') as HTMLElement;
            if (tempSelect) {
                // add disabled option to ort select
                const disabledOption = tempSelect.querySelector('option[data-parent="none"]') as HTMLOptionElement;
                if (disabledOption) {
                    let clonedOption = disabledOption.cloneNode(true);
                    ortSelect.appendChild(clonedOption);
                }

                // create temp otions
                const ortSelectOptions = tempSelect.querySelectorAll('option') as NodeListOf<HTMLOptionElement>;
                ortSelectOptions.forEach((option) => {
                    const parent = option.dataset.parent;
                    if (parent && parent === landSelect.value) {
                        let clonedOption = option.cloneNode(true);
                        ortSelect.appendChild(clonedOption);
                    }
                });
            }
        }

        // Set number of active projects
        this.filterNum();
    },

    heroSearch(event: Event) {
        const ortSelect = this.$root.querySelector('#filter_ort') as HTMLSelectElement;
        const ortSelectOptions = ortSelect.querySelectorAll('option') as NodeListOf<HTMLOptionElement>;
        const selectedOrt = ortSelectOptions[ortSelect.selectedIndex].value;
        const type = event.currentTarget ? (event.currentTarget as HTMLElement).getAttribute('data-type') : null;

        if (ortSelect) {
            const ortValue = selectedOrt;
            if (ortValue) {
                const urlParams = new URLSearchParams(window.location.search);
                urlParams.set('federal_states', '["' + ortValue + '"]');

                const siteLang = document.documentElement.lang;
                let langPrefix = '';
                if (siteLang != "de") {
                    langPrefix = '/' + siteLang;
                }

                if (type == "properties") {
                    window.location.replace(langPrefix + '/' + this.propertyPage + "?" + decodeURIComponent(urlParams.toString()));
                } else {
                    window.location.replace(langPrefix + '/' + this.projectPage + "?" + decodeURIComponent(urlParams.toString()));
                }
            }
        }
    },

    scrollToContent() {
        const elementToScroll = document.querySelector("#main section:nth-child(2)");
        if (elementToScroll) {
            elementToScroll.scrollIntoView({ behavior: "smooth" });
        }
    }
});
