import paginate from 'jw-paginate';

const MAX_PAGES = 5;

type PaginationOptions = {
  pageSize?: number;
  onUpdate?: (hasCurrentPageChanged?:boolean) => void;
}
//
// type AjaxListPaginationType = 'load-more-btn' | 'pagination';

export default class Pagination {
  state: {
    totalItems: number;
    currentPage: number;
    pageSize: number;
    totalPages: number;
    startPage: number;
    endPage: number;
    startIndex: number;
    endIndex: number;
    pages: number[];
  }

  onUpdate: (hasCurrentPageChanged?:boolean) => void;

  constructor(options?: PaginationOptions) {
    this.state = paginate(0, 1, options?.pageSize, MAX_PAGES);
    this.onUpdate = options?.onUpdate ?? (() => {});
  }

  goToPage(page: number): void {
    let hasCurrentPageChanged = false;

    if (this.state.currentPage !== page) {
      this.state.currentPage = page;
      hasCurrentPageChanged = true;
    }

    this.update(hasCurrentPageChanged);
  }

  setTotalItems(totalItems: number): void {
    this.state.totalItems = totalItems;
  }

  update(hasCurrentPageChanged = false): void {
    this.state = paginate(this.state.totalItems, this.state.currentPage, this.state.pageSize, MAX_PAGES);
    this.onUpdate(hasCurrentPageChanged);
  }
}
