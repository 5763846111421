import { AlpineComponent } from 'alpinejs';

type HeaderData = {
    headerBlur: boolean,
    headerNavOpen: boolean,
    langSwitchOpen: boolean,
    mainNavSize: () => void,
    toggleLangNav: () => void,
    toggleMainNav: () => void,
}

export default ():AlpineComponent<HeaderData> => ({
    headerBlur: false,
    headerNavOpen: true,
    langSwitchOpen: false,

    init() {
        // Main navigation dynamic size
        this.mainNavSize();
        window.addEventListener('resize', () => {
            this.mainNavSize();
        });

        // Header blur on scroll
        const SCROLL_DELTA = 50;
        window.addEventListener('scroll', () => {
            if (window.pageYOffset >= SCROLL_DELTA) {
                this.headerBlur = true;
            } else {
                this.headerBlur = false;
            }
        });
    },

    toggleLangNav() {
        this.langSwitchOpen = !this.langSwitchOpen;
    },

    mainNavSize() {
        const screenWidth = window.innerWidth;

        if (screenWidth > 1279) {
            // Opened navigation on desktop as default
            this.headerNavOpen = true;
            document.body.classList.add('main-nav-opened');
            const navItems = this.$root.querySelectorAll('.main-nav-item');
            if (navItems) {
                navItems.forEach((item: Element) => {
                    setTimeout(() => {
                        item.classList.add('visible');
                    }, 200);
                });
            }

            // Main navigation collapse
            const navCollapse = this.$root.querySelector('.header-navigation-collapse') as HTMLElement;
            const navToggle = this.$root.querySelector('.header-navigation-toggle') as HTMLElement;
            const firstNavItem = this.$root.querySelector('.main-nav-item:first-child') as HTMLElement;
            const langSwitch = this.$root.querySelector('.header-langswitch') as HTMLElement;
            const paddings = 200;
            if (navCollapse && navToggle && firstNavItem && langSwitch) {
                const defaultWidth = navToggle.clientWidth + firstNavItem.clientWidth + langSwitch.clientWidth + paddings;
                navCollapse.style.width = `${defaultWidth}px`;
            }
        } else {
            // Main navigation collapse
            const navCollapse = this.$root.querySelector('.header-navigation-collapse') as HTMLElement;
            if (navCollapse) {
                navCollapse.style.width = '';
            }

            // Closed navigation on mobile as default
            this.headerNavOpen = false;
            document.body.classList.remove('main-nav-opened');
            const headerNav = this.$root.querySelector('.header-navigation');
            if (headerNav) {
                headerNav.classList.remove('active');
            }
        }
    },

    toggleMainNav() {
        this.headerNavOpen = !this.headerNavOpen;
        const navItems = this.$root.querySelectorAll('.main-nav-item');

        if (this.headerNavOpen) {
            document.body.classList.add('main-nav-opened');

            navItems.forEach((item: Element) => {
                setTimeout(() => {
                    item.classList.add('visible');
                }, 200);
            });
        } else {
            document.body.classList.remove('main-nav-opened');

            navItems.forEach((item: Element) => {
                item.classList.remove('visible');
            });
        }
    }
});
