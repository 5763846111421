import { AlpineComponent } from "alpinejs";
import {
    default as AjaxList,
    ListXDataContext as AjaxListXDataContext,
    ListOptions as AjaxListOptions
} from '../../components/AjaxList/Classes/List';
import ListItem from "../../components/AjaxList/Classes/Item";
import { IHistoryStateProps } from "../../utils/historyState";

export type ListComponent = AlpineComponent<AjaxListXDataContext<ListItem> & {
    activeFilters: { uid: string, label: string }[];
    filterOpen: boolean;
    heroOpened: boolean;
    listFilters: () => void;
    toggleFilter: () => void;
}>;

export type ListOptions = AjaxListOptions & IHistoryStateProps;

const List = (options: ListOptions, filterData = {}):ListComponent => {
    return {
        ...AjaxList(options, filterData),
        activeFilters: [],
        filterOpen: false,
        heroOpened: true,

        created() {
            this.listFilters();

            // Hero search state
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            this.heroOpened = !urlParams.has('federal_states');
        },

        listFilters() {
            function removeDuplicates(arr: any[]) {
                return arr.filter((item, index) => arr.indexOf(item) === index);
            }

            // Merge filter ids
            let filterIds: any[] = [];
            if (this.filter) {
                if (this.filter.data.states) {
                    filterIds = filterIds.concat(this.filter.data.states);
                }
                if (this.filter.data.federal_states) {
                    filterIds = filterIds.concat(this.filter.data.federal_states);
                }
                filterIds = removeDuplicates(filterIds);
            }

            // Set activeFilters
            filterIds.forEach(uid => {
                const filterItem = this.$el.querySelector(`.filter-input[value="${uid}"]`) as HTMLElement;
                if (filterItem) {
                    const label = filterItem.dataset.title || "";
                    this.activeFilters.push({ uid, label });
                }
            });
        },

        toggleFilter() {
            this.filterOpen = !this.filterOpen;
            document.body.classList.toggle('filter-opened');
        }
    }
}

export default List;
