import { AlpineComponent } from 'alpinejs';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

type ImageTextTeaserData = {
    setImageSizes: () => void,
}

export default ():AlpineComponent<ImageTextTeaserData> => ({
    init() {
        this.$lazyLoad.update();

        // Calculate image sizes on mobile
        this.setImageSizes();

        window.addEventListener('resize', () => {
            this.setImageSizes();
        });

        // Image animations
        let mm = gsap.matchMedia();

        const imageWrapper = this.$root.querySelectorAll('.ce-image-text-teaser-image-wrapper');
        const gsapImage = this.$root.querySelectorAll('.ce-image-text-teaser-image-small img');
        if (imageWrapper && gsapImage) {
            mm.add("(min-width: 992px)", () => {
                gsap.from(gsapImage, {
                    y: 300,
                    scrollTrigger: {
                        trigger: imageWrapper,
                        scrub: true,
                        start: "top bottom",
                        end: "bottom top"
                    },
                })
            });
        }
    },

    setImageSizes() {
        const screenWidth = window.innerWidth;
        if (screenWidth < 992) {
            const images = this.$root.querySelectorAll('.ce-image-text-teaser-img');
            if (images) {
                images.forEach((image: Element) => {
                    const imageWidth = (image as HTMLElement).offsetWidth;
                    if (imageWidth) {
                        (image as HTMLElement).style.minHeight = `${imageWidth}px`;
                    }
                });
            }
        } else {
            // remove minHeight from all images
            const images = this.$root.querySelectorAll('.ce-image-text-teaser-img');
            if (images) {
                images.forEach((image: Element) => {
                    (image as HTMLElement).style.minHeight = '';
                });
            }
        }
    }
});
