import { AlpineComponent } from 'alpinejs';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

type ImagesVideosData = {
    swiperResize: (swiper: Swiper) => void,
}

export default (sliderType: string = 'half' ):AlpineComponent<ImagesVideosData> => ({
    init() {
        const swiperEl = this.$root.querySelector('.swiper') as HTMLElement;
        if (swiperEl) {
            // Slider type
            let spv = 1.17;
            if (sliderType == 'full') {
                spv = 1;
            }

            new Swiper(swiperEl, {
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: true,
                },
                speed: 1000,
                loop: false,
                autoHeight: true,
                slidesPerView: spv,
                spaceBetween: 22,
                threshold: 10,
                centerInsufficientSlides: true,
                watchOverflow: true,
                followFinger: true,
                updateOnWindowResize: true,
                grabCursor: true,
                modules: [Navigation, Pagination],
                navigation: {
                    prevEl: this.$root.querySelector<HTMLElement>('.ce-images-videos-btn--prev'),
                    nextEl: this.$root.querySelector<HTMLElement>('.ce-images-videos-btn--next'),
                },
                pagination: {
                    el: '.ce-images-videos-pagination',
                    clickable: true,
                },
                on: {
                    init: function () {
                        const playBtns = swiperEl.querySelectorAll('.ce-images-videos-item-btn--play') as NodeListOf<HTMLElement>;
                        if (playBtns) {
                            playBtns.forEach((playBtn) => {
                                playBtn.addEventListener('click', () => {
                                    // @ts-ignore
                                    const videoItem = playBtn.parentElement.parentElement;
                                    if (videoItem) {
                                        const videoType = videoItem.dataset.type;
                                        if (videoType == 'mp4') {
                                            const video = videoItem.querySelector('.ce-images-videos-item--video') as HTMLVideoElement;
                                            if (video) {
                                                videoItem.classList.add('is-playing');
                                                video.play();

                                                // Hide caption
                                                const caption = videoItem.querySelector('.ce-images-videos-item-caption') as HTMLElement;
                                                if (caption) {
                                                    caption.classList.add('d-none');
                                                }
                                            }
                                        } else if (videoType == 'youtube') {
                                            const video = videoItem.querySelector('.ce-images-videos-item--video') as HTMLIFrameElement;
                                            if (video) {
                                                let url = (video as HTMLElement).dataset.src;
                                                url = url + "&autoplay=1&mute=1";
                                                video.src = url;

                                                videoItem.classList.add('is-playing');

                                                // Hide caption
                                                const caption = videoItem.querySelector('.ce-images-videos-item-caption') as HTMLElement;
                                                if (caption) {
                                                    caption.classList.add('d-none');
                                                }
                                            }
                                        } else if (videoType == 'vimeo') {
                                            const video = videoItem.querySelector('.ce-images-videos-item--video') as HTMLIFrameElement;
                                            if (video) {
                                                let url = (video as HTMLElement).dataset.src;
                                                url = url + "&autoplay=1&muted=1";
                                                video.src = url;

                                                videoItem.classList.add('is-playing');

                                                // Hide caption
                                                const caption = videoItem.querySelector('.ce-images-videos-item-caption') as HTMLElement;
                                                if (caption) {
                                                    caption.classList.add('d-none');
                                                }
                                            }
                                        }
                                    }
                                });
                            });
                        }
                    },
                    slideChange: () => {
                        const videoItems = swiperEl.querySelectorAll('.ce-images-videos-item') as NodeListOf<HTMLElement>;
                        if (videoItems) {
                            videoItems.forEach((videoItem) => {
                                if (videoItem) {
                                    const videoType = (videoItem as HTMLElement).dataset.type;
                                    if (videoType == 'mp4') {
                                        const video = videoItem.querySelector('.ce-images-videos-item--video') as HTMLVideoElement;
                                        if (video) {
                                            video.pause();
                                            video.currentTime = 0;
                                        }
                                    } else if (videoType == 'youtube') {
                                        const video = videoItem.querySelector('.ce-images-videos-item--video') as HTMLIFrameElement | null;
                                        if (video) {
                                            // @ts-ignore
                                            video.contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
                                        }
                                    } else if (videoType == 'vimeo') {
                                        const video = videoItem.querySelector('.ce-images-videos-item--video') as HTMLIFrameElement;
                                        if (video) {
                                            // @ts-ignore
                                            video.src = (video as HTMLElement).dataset.src;
                                        }
                                    }
                                }
                            });
                        }
                    },
                    slidesLengthChange: () => {
                        this.$lazyLoad.update();
                    },
                }
            });
        } else {
            if (this.$root.classList.contains("ce-images-videos--single")) {
                const playBtn = this.$root.querySelector('.ce-images-videos-item-btn--play') as HTMLElement;
                if (playBtn) {
                    playBtn.addEventListener('click', () => {
                        // @ts-ignore
                        const videoItem = playBtn.parentElement.parentElement;
                        if (videoItem) {
                            const videoType = videoItem.dataset.type;
                            if (videoType == 'mp4') {
                                const video = videoItem.querySelector('.ce-images-videos-item--video') as HTMLVideoElement;
                                if (video) {
                                    videoItem.classList.add('is-playing');
                                    video.play();

                                    // Hide caption
                                    const caption = videoItem.querySelector('.ce-images-videos-item-caption') as HTMLElement;
                                    if (caption) {
                                        caption.classList.add('d-none');
                                    }
                                }
                            } else if (videoType == 'youtube') {
                                const video = videoItem.querySelector('.ce-images-videos-item--video') as HTMLIFrameElement;
                                if (video) {
                                    let url = (video as HTMLElement).dataset.src;
                                    url = url + "&autoplay=1&mute=1";
                                    video.src = url;

                                    videoItem.classList.add('is-playing');

                                    // Hide caption
                                    const caption = videoItem.querySelector('.ce-images-videos-item-caption') as HTMLElement;
                                    if (caption) {
                                        caption.classList.add('d-none');
                                    }
                                }
                            } else if (videoType == 'vimeo') {
                                const video = videoItem.querySelector('.ce-images-videos-item--video') as HTMLIFrameElement;
                                if (video) {
                                    let url = (video as HTMLElement).dataset.src;
                                    url = url + "&autoplay=1&muted=1";
                                    video.src = url;

                                    videoItem.classList.add('is-playing');

                                    // Hide caption
                                    const caption = videoItem.querySelector('.ce-images-videos-item-caption') as HTMLElement;
                                    if (caption) {
                                        caption.classList.add('d-none');
                                    }
                                }
                            }
                        }
                    });
                }
            }
        }
    },

    swiperResize(swiper: Swiper) {
        this.$lazyLoad.update();
        swiper.update();
    }
});
